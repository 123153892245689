function newScript() {
  !(function (e, t, n) {
    function a() {
      const e = t.getElementsByTagName('script')[0];
      const n = t.createElement('script');
      n.type = 'text/javascript';
      n.async = !0;
      n.src = 'https://beacon-v2.helpscout.net';
      e.parentNode.insertBefore(n, e);
    }
    if (
      ((e.Beacon = n = function(t, n, a) {
        e.Beacon.readyQueue.push({ method: t, options: n, data: a });
      }),
      (n.readyQueue = []),
      t.readyState === 'complete')
    )
      return a();
    e.attachEvent ? e.attachEvent('onload', a) : e.addEventListener('load', a, !1);
  })(window, document, window.Beacon || (() => {}));
  //   alert("okay");
}
function anotherScript() {
  window.Beacon('init', 'c5582108-0141-4217-b393-8be86683ea0d');
}
